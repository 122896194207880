import { MentiError, captureException } from '@mentimeter/errors/sentry';
import { getVisitorToken } from '@mentimeter/splitio';
import { trackTriedToLoginWithSSO } from '@mentimeter/dashboard-tracking';
import {
  isUnknownSSOErrorCode,
  ssoSendVisitorTrackingError,
} from '@mentimeter/sso-data-handlers';
import SAMLError from './SAMLError';
import type { ResponseError } from './types';

export default function SAMLErrorHandler({
  error,
}: {
  error: ResponseError;
}): React.ReactNode {
  if (isUnknownSSOErrorCode(error.code)) {
    captureException(
      new MentiError('Unknown SAML Error Code', {
        feature: 'sso',
        cause: error.code,
      }),
    );
  }

  if (error.data?.query) {
    trackTriedToLoginWithSSO(
      error.data?.query,
      getVisitorToken(),
      ssoSendVisitorTrackingError,
    );
  }

  return (
    <div data-testid="saml-error">
      <SAMLError error={error} />
    </div>
  );
}
